import * as R from 'ramda';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import { arrayOf, shape, string, func } from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import { insertQueryParamsIntoURL, isNilOrEmpty } from 'poly-utils';
import { useNavigate, useParams } from 'poly-client-routing';

import {
  FlexColumnCentered,
  FlexColumnContainer,
} from '../../components/Containers.js';
import { routes } from '../../routes.js';
import { Button } from '../../components/Button.js';
import { Selector } from '../../components/Selector.js';
import { Redirect } from '../../components/Navigation.js';
import { ErrorText, Text } from '../../components/Text.js';
import { ProjectIdText } from '../ExistingProjectScreen.js';
import { CreateWorkOrderButton } from './CreateWorkOrderButton.js';
import { AssetDetailsContainer } from '../AssetDetailsContainer.js';
import { assetScannerIndexedDb } from '../../offline/indexedDb/indexedDb.js';
import { useAssetCheckInProjectsQuery } from './useAssetCheckInProjectsQuery.js';
import { assetProceduresStoreName } from '../../offline/indexedDb/indexedDbStores.js';
import { CachedEntitySyncStatuses } from '../../offline/cache/helpers.js';

const ButtonsWrapper = styled.div`
  width: 100%;
`;

// getAssetProjectSuppliers :: String -> Asset -> [AssetScannerSupplier]
const getAssetProjectSuppliers = (projectId) =>
  R.compose(
    R.pathOr([], ['suppliers']),
    R.find(R.propEq('projectId', projectId)),
    R.propOr([], 'checkInProjects'),
  );

// getAssetProcedure :: String -> Asset -> Procedure
const getAssetProcedure = (projectId) =>
  R.compose(
    R.unless(
      R.isNil,
      R.applySpec({
        assetId: R.path(['asset', '_id']),
        steps: R.path(['procedure', 'steps']),
        procedureId: R.path(['procedure', '_id']),
        executedProcedureId: R.path(['executedProcedure', '_id']),
      }),
    ),
    R.converge(R.find, [
      R.compose(R.pathEq(['asset', '_id']), R.prop('assetId')),
      R.propOr([], 'assetProcedures'),
    ]),
    R.find(R.propEq('projectId', projectId)),
    R.converge(R.map, [
      R.compose(R.assoc('assetId'), R.prop('_id')),
      R.propOr([], 'checkInProjects'),
    ]),
  );

// getAssetSupplierId :: AssetSupplier -> String
const getAssetSupplierId = R.prop('supplierId');

// getSuppliersOptions :: [AssetSupplier] -> [Option]
const getSuppliersOptions = R.map(
  R.applySpec({ id: R.prop('supplierId'), title: R.prop('supplierName') }),
);

const SelectorContainer = styled(FlexColumnCentered)`
  margin-top: 20px;
`;

export function SuppliersSelector({
  suppliers,
  selectedSupplierId,
  setSelectedSupplierId,
}) {
  const options = getSuppliersOptions(suppliers);
  return (
    <SelectorContainer>
      <Selector
        optionWidth="100%"
        optionHeight="50px"
        value={selectedSupplierId}
        onClick={setSelectedSupplierId}
        options={options}
      />
    </SelectorContainer>
  );
}

const assetSupplierPropTypes = shape({
  supplierId: string.isRequired,
  supplierName: string.isRequired,
});

SuppliersSelector.propTypes = {
  selectedSupplierId: string,
  setSelectedSupplierId: func.isRequired,
  suppliers: arrayOf(assetSupplierPropTypes),
};

const checkInSupplierMutation = gql`
  mutation checkInSupplierMutation($input: CheckInSupplierInput!) {
    checkInSupplier(input: $input) {
      _id
    }
  }
`;

const Container = styled(FlexColumnContainer)`
  width: 100%;
`;

// generateMaintenanceProcedureId :: Object -> String
const generateMaintenanceProcedureId = R.compose(
  R.join('_'),
  R.juxt([
    R.path(['assetProcedure', 'procedureId']),
    R.prop('assetQrCodeId'),
    R.prop('projectId'),
  ]),
);

// checkIfProcedureMaintenanceActive :: ProjectAssetProcedure -> Boolean
const checkIfProcedureMaintenanceActive = R.both(
  R.complement(isNilOrEmpty),
  R.propSatisfies(isNilOrEmpty, 'executedProcedureId'),
);

function CheckInButton({
  projectId,
  supplierId,
  assetQrCodeId,
  assetProcedure,
}) {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [servicingStarted, setServicingStarted] = useState(false);
  const [checkInSupplier, data] = useMutation(checkInSupplierMutation);

  const isProcedureMaintenanceActive = useMemo(
    () => checkIfProcedureMaintenanceActive(assetProcedure),
    [assetProcedure],
  );

  const maintenanceProcedureId = useMemo(
    () =>
      generateMaintenanceProcedureId({
        projectId,
        assetQrCodeId,
        assetProcedure,
      }),
    [projectId, assetQrCodeId, assetProcedure],
  );

  const handleAssetProcedureMaintenance = async () => {
    try {
      const iDb = await assetScannerIndexedDb();

      const cashedAssetProcedure = await iDb.getById(
        assetProceduresStoreName,
        maintenanceProcedureId,
      );

      if (!cashedAssetProcedure) {
        await iDb.createOrUpdate(assetProceduresStoreName, {
          idbKey: maintenanceProcedureId,
          ...assetProcedure,
          projectId,
          supplierId,
          syncStatus: CachedEntitySyncStatuses.DRAFT,

          assetProceduresStoreName,
          maintenanceProcedureId,
        });
      }

      navigate(
        insertQueryParamsIntoURL(
          { maintenanceProcedureId },
          routes.assetProcedureSteps,
        ),
      );
    } catch (err) {
      setError(err.message);
    }
  };

  const handleCheckInSupplier = async () => {
    try {
      await checkInSupplier({
        variables: { input: { projectId } },
      });

      navigate(insertQueryParamsIntoURL({ projectId }, routes.checkInSuccess));
    } catch (err) {
      setError(err.message);
    }
  };

  const onClick = async () => {
    if (isProcedureMaintenanceActive) {
      handleAssetProcedureMaintenance();
    } else {
      await handleCheckInSupplier();
    }
  };

  useEffect(() => {
    const getProcedureFromIndexedDb = async () => {
      try {
        const iDb = await assetScannerIndexedDb();

        const cashedAssetProcedure = await iDb.getById(
          assetProceduresStoreName,
          maintenanceProcedureId,
        );

        if (cashedAssetProcedure) {
          setServicingStarted(true);
        }
      } catch (err) {
        setError(err.message);
        setServicingStarted(false);
      }
    };

    if (isProcedureMaintenanceActive) {
      getProcedureFromIndexedDb();
    }
  }, []);

  const buttonCaption = useMemo(() => {
    if (isProcedureMaintenanceActive) {
      if (servicingStarted) {
        return 'Continue Servicing';
      }

      return 'Start Servicing';
    }

    return 'Check-In';
  }, [isProcedureMaintenanceActive, servicingStarted]);

  return (
    <Container>
      <ErrorText align="center">{error}</ErrorText>
      <Button
        onClick={onClick}
        disabled={!supplierId}
        loading={data.loading}
        caption={buttonCaption}
        {...(assetProcedure ? {} : { iconName: 'check' })}
      />
    </Container>
  );
}

CheckInButton.propTypes = {
  supplierId: string,
  projectId: string.isRequired,
  assetQrCodeId: string.isRequired,
  assetProcedure: shape({ procedureId: string.isRequired }),
};

// formatSingleSupplierMsg :: String -> String
const formatSingleSupplierMsg = (supplierName) =>
  `If you are with the service provider “${supplierName}”, please click the button bellow to check-in`;

// formatMsg :: [AssetScannerSupplier] -> String
const formatMsg = R.unless(
  R.isEmpty,
  R.ifElse(
    R.propSatisfies(R.gt(R.__, 1), 'length'),
    R.always(
      'If you are a service provider please select which, then please click the button bellow to check-in',
    ),
    R.compose(formatSingleSupplierMsg, R.prop('supplierName'), R.head),
  ),
);

export function ConfirmSupplierCheckInScreen() {
  const { projectId, assetQrCodeId } = useParams();

  const [selectedSupplierId, setSelectedSupplierId] = useState();

  const { asset, loading } = useAssetCheckInProjectsQuery();

  const suppliers = getAssetProjectSuppliers(projectId)(asset);
  const assetProcedure = getAssetProcedure(projectId)(asset);
  const suppliersLength = suppliers.length;
  const firstSupplier = suppliers[0];

  useEffect(() => {
    if (!selectedSupplierId && suppliersLength > 0) {
      setSelectedSupplierId(getAssetSupplierId(firstSupplier));
    }
  }, [suppliers]);

  if (loading) {
    return 'Loading...';
  }

  if (!projectId || suppliersLength === 0) {
    return <Redirect route={routes.notFoundProject} />;
  }

  return (
    <AssetDetailsContainer asset={asset}>
      <FlexColumnContainer>
        <FlexColumnCentered>
          <Text size={24}>Project</Text>
          <ProjectIdText>#{projectId}</ProjectIdText>
          <Text size={14} align="center">
            {formatMsg(suppliers)}
          </Text>
        </FlexColumnCentered>
      </FlexColumnContainer>
      <ButtonsWrapper>
        <CreateWorkOrderButton
          assetQrCodeId={assetQrCodeId}
          projectId={projectId}
          supplierId={selectedSupplierId}
        />
        <CheckInButton
          assetProcedure={assetProcedure}
          supplierId={selectedSupplierId}
          assetQrCodeId={assetQrCodeId}
          projectId={projectId}
        />
      </ButtonsWrapper>
    </AssetDetailsContainer>
  );
}
