import * as R from 'ramda';
import React, { useState } from 'react';
import styled from 'styled-components';
import { func, string } from 'prop-types';
import { commonFetchHeaders } from 'poly-client-utils';
import {
  API_PREFIX_PATH,
  CHECK_SUPPLIER_PIN_CODE_BY_QR_CODE_PATH,
} from 'poly-constants';

import { Button } from '../../components/Button.js';
import { ErrorText, SubTitleText } from '../../components/Text.js';

const settings = process.env;

const { GRAPHQL_HTTP_URL = '' } = settings;

const ButtonS = styled(Button)`
  width: 100%;
  color: #58628d;
  background: #fff;
  justify-content: flex-start;
  border: ${({ border }) => (border ? '2px solid  #49DBDB' : 'none')};
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const CHECK_SUPPLIER_URL = `${GRAPHQL_HTTP_URL}${API_PREFIX_PATH}${CHECK_SUPPLIER_PIN_CODE_BY_QR_CODE_PATH}`;

// isNoSupplierWithPinCode :: { suppliersCount: Int } -> Boolean
const isNoSupplierWithPinCode = R.compose(
  R.equals(0),
  R.propOr(0, 'suppliersCount'),
);

export function UserTypeComponent({ handleSelect, assetQrCodeId }) {
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState(null);
  const [isTechnician, setIsTechnician] = useState(null);

  const handleIsTechnicianSelect = async () => {
    try {
      setIsLoading(true);

      const response = await fetch(CHECK_SUPPLIER_URL, {
        method: 'POST',
        body: JSON.stringify({ assetQrCodeId }),
        headers: commonFetchHeaders,
        credentials: 'include',
      });

      if (response.ok) {
        const body = await response.json();

        if (isNoSupplierWithPinCode(body)) {
          return setError(
            'None of assigned suppliers have PIN enabled. Please, contact the support.',
          );
        }

        return handleSelect(isTechnician);
      }

      return setError(null);
    } catch (e) {
      return setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onButtonClick = isTechnician
    ? handleIsTechnicianSelect
    : () => handleSelect(isTechnician);

  return (
    <>
      <Wrapper>
        <SubTitleText>Are you Technician?</SubTitleText>
        <ButtonS
          border={isTechnician === true}
          caption="Yes, I am a Technician"
          onClick={() => setIsTechnician(true)}
        />
        <ButtonS
          border={isTechnician === false}
          caption="No, I am not a Technician"
          onClick={() => setIsTechnician(false)}
        />
      </Wrapper>

      {error && <ErrorText>{error}</ErrorText>}

      <Button
        iconName="send"
        caption="Submit"
        disabled={isLoading || isTechnician === null}
        onClick={onButtonClick}
      />
    </>
  );
}

UserTypeComponent.propTypes = {
  handleSelect: func.isRequired,
  assetQrCodeId: string,
};
