import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { gql, useMutation, useQuery } from '@apollo/client';
import { applyPathOrNothingUI, pathOrNothingUI } from 'poly-client-utils';
import { NOTHING_UI_STRING } from 'poly-constants';
import { formatDate, insertQueryParamsIntoURL } from 'poly-utils';
import { useNavigate, useRouterQuery } from 'poly-client-routing';
import { useUploadAttachment } from 'poly-client-utils/src/files/useUploadAttachment.js';

import { Image } from '../../components/Image.js';
import { ScreenHeader } from '../../components/ScreenHeader.js';
import { BlockWithLabel } from '../../components/BlockWithLabel.js';
import { FlexColumnContainer } from '../../components/Containers.js';
import { useAssetTypes } from '../../components/AssetTypeSelect.js';
import { useAssetsMakes } from '../../components/AssetMakeSelect.js';
import { useNetworkStatus } from '../../providers/NetworkStatusProvider.js';
import { assetScannerIndexedDb } from '../../offline/indexedDb/indexedDb.js';
import {
  assetModelsStoreName,
  assetsStoreName,
} from '../../offline/indexedDb/indexedDbStores.js';
import { useAssetFromIndexedDb } from '../EditAssetQueueScreen/useAssetFromIndexedDb.js';
import { FormFooterContainer } from './styles.js';
import { Button } from '../../components/Button.js';
import { routes } from '../../routes.js';
import {
  addAssetMutation,
  formatCreateAssetMutationInput,
} from './assetFormHelpers.js';
import { CachedEntitySyncStatuses } from '../../offline/cache/helpers.js';
import { useModalContext } from '../../providers/ModalProvider.js';
import { ErrorModal } from '../EditAssetQueueScreen/ErrorModal.js';
import { publishIdbStoreChangedEvent } from '../../offline/indexedDb/indexedDbStoreChangeEvent.js';
import { Redirect } from '../../components/Navigation.js';

const DescriptionBlock = styled.div`
  padding: 24px;
`;

const ContentContainer = styled(FlexColumnContainer)`
  background-color: #fff;
  flex-grow: 1;
  padding: 24px;
`;

const Wrapper = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
`;

// formValuesToPreview :: Object -> Object
const formValuesToPreview = R.applySpec({
  typeId: R.prop('typeId'),
  newModelName: R.prop('newModelName'),
  newManufacturerName: R.prop('newManufacturerName'),
  description: pathOrNothingUI(['description']),
  photo: R.compose(
    R.when(R.identity, (photo) => URL.createObjectURL(new Blob([photo]))),
    R.prop('photo'),
  ),
  manufacturerId: R.path(['manufacturerId']),
  modelId: R.path(['modelId']),
  warrantyEnd: applyPathOrNothingUI(['warrantyEnd'], formatDate),
  serial: pathOrNothingUI(['serial']),
  location: pathOrNothingUI(['location']),
  qrCodeId: pathOrNothingUI(['qrCodeId']),
  withNewMake: R.propOr(false, 'withNewMake'),
  withNewModel: R.propOr(false, 'withNewModel'),
});

const assetMakeQuery = gql`
  query assetMakeQuery($id: ID!, $typeId: ID!) {
    assetManufacturer(id: $id) {
      name
      assetModels {
        _id
        name
      }
    }
    assetType(id: $typeId) {
      _id
      name
    }
  }
`;

// getManufacturerModelById :: ID -> AssetManufacturerQueryResult -> String
const getManufacturerModelById = (id) =>
  R.compose(
    pathOrNothingUI(['name']),
    R.find(R.propEq('_id', id)),
    R.pathOr([], ['assetManufacturer', 'assetModels']),
  );

const usePreviewDetails = ({ manufacturerId, typeId, modelId }) => {
  const { data } = useQuery(assetMakeQuery, {
    variables: {
      id: manufacturerId,
      typeId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !manufacturerId || !typeId,
  });

  const typeName = R.pathOr(NOTHING_UI_STRING, ['assetType', 'name'], data);
  const manufacturer = R.pathOr(
    NOTHING_UI_STRING,
    ['assetManufacturer', 'name'],
    data,
  );
  const model = modelId
    ? getManufacturerModelById(modelId)(data)
    : NOTHING_UI_STRING;

  return { manufacturer, typeName, model };
};

// getAssetTypeNameById :: (SearchAssetTypesResult, ID) -> String
const getAssetTypeNameById = (data, id) =>
  R.compose(
    R.prop('name'),
    R.find(R.propEq('_id', id)),
    R.propOr([], 'assetTypes'),
  )(data);

// getAssetMakeNameById :: (SearchAssetManufacturesResult, ID) -> String
const getAssetMakeNameById = (data, id) =>
  R.compose(
    R.prop('name'),
    R.find(R.propEq('_id', id)),
    R.pathOr([], ['searchAssetManufactures', 'hits']),
  )(data);

export const usePreviewDetailsCache = ({
  manufacturerId,
  typeId,
  modelId,
  isOnline,
}) => {
  const [model, setModel] = useState(NOTHING_UI_STRING);

  useEffect(() => {
    if (!isOnline && modelId) {
      const getModelFormIDb = async () => {
        const iDb = await assetScannerIndexedDb();

        const assetModel = await iDb.getById(assetModelsStoreName, modelId);

        setModel(assetModel?.name);
      };

      getModelFormIDb();
    }
  }, [modelId, isOnline]);

  const { data: assetTypeData } = useAssetTypes(isOnline);

  const { data: assetMakesData } = useAssetsMakes('', isOnline);

  const manufacturer = getAssetMakeNameById(assetMakesData, manufacturerId);

  const typeName = getAssetTypeNameById(assetTypeData, typeId);

  return { manufacturer, typeName, model };
};

const usePreviewDetailsWithCache = ({ manufacturerId, typeId, modelId }) => {
  const { isOnline } = useNetworkStatus();

  const previewDetails = usePreviewDetails({
    manufacturerId,
    typeId,
    modelId,
  });

  const cachePreviewDetails = usePreviewDetailsCache({
    manufacturerId,
    typeId,
    modelId,
    isOnline,
  });

  return isOnline ? previewDetails : cachePreviewDetails;
};

const addAssetErrorModalId = 'addAssetErrorModalId';

export function AssetPreview() {
  const { idbKey, propertyId } = useRouterQuery(['idbKey', 'propertyId']);
  const { asset, loading } = useAssetFromIndexedDb(idbKey);

  const {
    photo,
    typeId,
    serial,
    modelId,
    location,
    qrCodeId,
    description,
    warrantyEnd,
    withNewMake,
    withNewModel,
    newModelName,
    manufacturerId,
    newManufacturerName,
  } = formValuesToPreview(asset);

  const uploadFile = useUploadAttachment(true);

  const { manufacturer, typeName, model } = usePreviewDetailsWithCache({
    manufacturerId,
    typeId,
    modelId,
  });

  const [addAsset] = useMutation(addAssetMutation);

  const goBackRoute = insertQueryParamsIntoURL(
    { idbKey, assetQrCodeId: qrCodeId, ...(propertyId ? { propertyId } : {}) },
    routes.addAsset,
  );

  const { isOnline } = useNetworkStatus();
  const { openModal, closeModal } = useModalContext();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => closeModal(addAssetErrorModalId);

  const navigateToAddAssetSuccess = () =>
    navigate(
      propertyId
        ? insertQueryParamsIntoURL({ propertyId }, routes.addAssetSuccess)
        : routes.addAssetSuccess,
    );

  const handleSubmitAsset = async (e) => {
    e.preventDefault();
    const iDb = await assetScannerIndexedDb();

    const input = formatCreateAssetMutationInput(asset);

    try {
      setIsSubmitting(true);
      if (isOnline) {
        let uploadedPhotoId = null;

        if (asset?.photo) {
          uploadedPhotoId = await uploadFile(asset?.photo);
        }
        await addAsset({
          variables: {
            input: {
              ...input,
              ...(uploadedPhotoId ? { uploadedPhotoId } : {}),
            },
          },
        });
        await iDb.deleteById(assetsStoreName, asset.idbKey);
      } else {
        await iDb.update(
          assetsStoreName,
          { syncStatus: CachedEntitySyncStatuses.PENDING },
          asset.idbKey,
        );
      }
      navigateToAddAssetSuccess();
      publishIdbStoreChangedEvent();
    } catch (error) {
      openModal({
        id: addAssetErrorModalId,
        content: (
          <ErrorModal
            errorMsg={error.message}
            handleClose={handleClose}
            goHome={() => navigate(routes.home)}
          />
        ),
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return null;
  }

  if (!loading && !asset) {
    return <Redirect route={routes.home} />;
  }

  return (
    <Wrapper>
      <ScreenHeader title={typeName} route={goBackRoute} goBack />
      <Image src={photo} />
      <DescriptionBlock>{description}</DescriptionBlock>
      <ContentContainer>
        <BlockWithLabel
          value={
            withNewMake && !!newManufacturerName
              ? newManufacturerName
              : manufacturer
          }
          label="Make"
        />
        <BlockWithLabel
          value={withNewModel && !!newModelName ? newModelName : model}
          label="Model #"
        />
        <BlockWithLabel value={warrantyEnd} label="Warranty Expiration Date" />
        <BlockWithLabel value={serial} label="Serial Number" />
        <BlockWithLabel value={location} label="Location Description" />
        <BlockWithLabel value={qrCodeId} label="Asset Tag" />
      </ContentContainer>
      <FormFooterContainer>
        <Button
          iconName="documentConfirm"
          caption="Create Asset Details"
          loading={isSubmitting}
          onClick={handleSubmitAsset}
        />
      </FormFooterContainer>
    </Wrapper>
  );
}
