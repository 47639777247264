import { TOKEN_VIA_SMS_PATH, API_PREFIX_PATH } from 'poly-constants';
import { useState } from 'react';
import { commonFetchHeaders } from 'poly-client-utils';

const settings = process.env;

const { GRAPHQL_HTTP_URL = '' } = settings;

const DEFAULT_REQUEST_URL = `${GRAPHQL_HTTP_URL}${API_PREFIX_PATH}${TOKEN_VIA_SMS_PATH}`;

export const useLoginRequest = () => {
  const [loading, setLoading] = useState(false);

  const request = async (requestBody, requestURL) => {
    let errorMessage = null;

    setLoading(true);
    const response = await fetch(requestURL || DEFAULT_REQUEST_URL, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: commonFetchHeaders,
      credentials: 'include',
    });

    if (!response.ok) {
      const body = await response.json();
      errorMessage = body.message;
    }

    setLoading(false);

    return { responseStatus: response.status, errorMessage };
  };

  return {
    request,
    loading,
  };
};
