import * as R from 'ramda';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import {
  insertQueryParamsIntoURL,
  insertParamsIntoURL,
  isNilOrEmpty,
} from 'poly-utils';
import { useParams, useNavigate } from 'poly-client-routing';

import { routes } from '../routes.js';
import { Selector } from '../components/Selector.js';
import { ASSET_SCANNER_USER_TYPE_KEY } from './Login/LoginScreen.js';
import { AssetDetailsHeaderWithLogo } from '../components/AssetDetailsHeaderWithLogo.js';
import { useAssetDetailsByQrCode } from '../hooks/useAssetDetails.js';
import { isAssetInactive } from './ScanQrCode/common.js';
import { Redirect } from '../components/Navigation.js';

const ISSUES = {
  notWorking: 'Not working',
  makingNoise: 'Making Noise',
  brokenPart: 'Broken Part',
  other: 'Other',
};

const options = Object.keys(ISSUES).map((key) => ({
  id: key,
  title: ISSUES[key],
}));

const SelectorContainer = styled.div`
  padding: 38px;
`;

const useIsSupplierUser = () => {
  const isSupplierUser = JSON.parse(
    localStorage.getItem(ASSET_SCANNER_USER_TYPE_KEY),
  );

  return isSupplierUser;
};

// isAllowProjectCreation :: {client: Client} -> Boolean
const isAllowProjectCreation = R.pathOr(true, [
  'client',
  'configs',
  'assetScannerApp',
  'allowProjectCreation',
]);

export function CreateProjectScreen() {
  const navigate = useNavigate();

  const { assetQrCodeId } = useParams();
  const { asset, loading } = useAssetDetailsByQrCode(assetQrCodeId);

  const isSupplierUser = useIsSupplierUser();

  useEffect(() => {
    if (isNilOrEmpty(asset) && !loading && !isSupplierUser) {
      navigate(
        insertQueryParamsIntoURL(
          { message: 'Asset not found' },
          routes.helpDefault,
        ),
      );
    }
  }, [asset]);

  if (loading) {
    return 'Loading...';
  }

  if (isSupplierUser) {
    const route = insertParamsIntoURL(
      { assetQrCodeId },
      routes.supplierCheckIn,
    );
    return <Redirect route={route} />;
  }

  const existsProjects = R.pathOr([], ['activeProjects'], asset);

  if (!isSupplierUser && existsProjects.length > 0) {
    const route = insertParamsIntoURL(
      { assetQrCodeId },
      routes.existingProject,
    );
    return <Redirect route={route} />;
  }

  if (isAssetInactive(asset)) {
    const route = insertParamsIntoURL(
      { assetQrCodeId },
      routes.scanQrCodeInactive,
    );
    return <Redirect route={route} />;
  }

  if (!isAllowProjectCreation(asset)) {
    return <Redirect route={routes.forbiddenProjectCreation} />;
  }

  const onClick = (id) => {
    const projectDescription = ISSUES[id];
    const nextUrl = insertParamsIntoURL(
      { assetQrCodeId, projectDescription },
      routes.submitProject,
    );
    navigate(nextUrl);
  };

  return (
    <>
      <AssetDetailsHeaderWithLogo asset={asset} />
      <SelectorContainer>
        <Selector
          options={options}
          optionWidth="calc(50% - 10px)"
          optionHeight="74px"
          onClick={onClick}
        />
      </SelectorContainer>
    </>
  );
}
