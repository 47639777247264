import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRouterQuery } from 'poly-client-routing';

import { FlexColumnCentered } from '../../components/Containers.js';
import { Text } from '../../components/Text.js';
import { ProjectIdText } from '../ExistingProjectScreen.js';
import { SuccessScreen } from '../StatusScreen.js';
import { PhoneButtonLink } from '../../components/PhoneLink.js';
import { useNetworkStatus } from '../../providers/NetworkStatusProvider.js';
import { getIdbEntityCountsByStoreNameP } from '../../offline/useEntitySyncStatusQueue.js';
import {
  subscribeIdbStoreChange,
  unsubscribeIdbStoreChange,
} from '../../offline/indexedDb/indexedDbStoreChangeEvent.js';
import { assetProceduresStoreName } from '../../offline/indexedDb/indexedDbStores.js';

const TextContainer = styled(FlexColumnCentered)`
  padding: 0 40px;
`;

const useLogOutSupplierAfterSyncQueue = ({ isOnline }) => {
  useEffect(() => {
    const logoutUserP = async () => {
      if (isOnline) {
        await getIdbEntityCountsByStoreNameP(assetProceduresStoreName);
      }
    };

    subscribeIdbStoreChange(logoutUserP);

    return () => unsubscribeIdbStoreChange(logoutUserP);
  }, [isOnline]);
};

export function CheckInSuccessScreen() {
  const { projectId, isProcedureExecuted } = useRouterQuery([
    'projectId',
    'isProcedureExecuted',
  ]);

  const { isOnline } = useNetworkStatus();

  useLogOutSupplierAfterSyncQueue({ isOnline });

  const message = `Thank you for check-in${
    isProcedureExecuted ? ' and completing the checkup service' : ''
  }.`;

  const successScreenText = isOnline
    ? message
    : 'Your check-in and completion of the checkup service have been added to the queue and will be submitted once you reconnect to the internet.';

  return (
    <SuccessScreen>
      <TextContainer>
        <Text size={22}>
          {isProcedureExecuted ? 'Procedure Completed' : 'Check-In Confirmed'}
        </Text>
        <ProjectIdText>Request #{projectId}</ProjectIdText>
        <Text size={14} align="center">
          {successScreenText} <br />
          If you have any questions you can reach us by phone.
        </Text>
      </TextContainer>
      <PhoneButtonLink />
    </SuccessScreen>
  );
}
